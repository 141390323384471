<template>
  <div class="wrap">
    <div class="main-container">
      <div class="block-left">
        <img src="@img/logo_login.png" alt class="logo" />
        <img src="@img/dec_login.png" alt class="l-block-dec" />
      </div>

      <div class="block-right">
        <div class="header">
          <h2>Hello，欢迎登录后台</h2>
          <h3>WELCOME TO THE SYSTEM</h3>
        </div>

        <div class="form">
          <input
            type="text"
            class="input"
            placeholder="请输入账号"
            autocomplete="off"
            maxlength="15"
            v-model="username"
          />
          <input
            type="password"
            class="input"
            placeholder="请输入密码"
            autocomplete="off"
            v-model="password"
          />
          <div class="captcha-wrap">
            <input
              type="text"
              class="input"
              placeholder="请输入验证码"
              maxlength="6"
              v-model="captcha"
              @keyup.enter="doLogin"
            />
            <transition name="el-fade-in">
              <img
                alt="验证码"
                title="点击可更换验证码"
                class="img-code-box"
                :src="captchaSrc"
                v-if="captchaSrc"
                @click="doCaptchaQuery"
              />
            </transition>
          </div>
          <el-button class="btn-login" @click="doLogin">登录</el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { CaptchaQuery, LoginVerifyCodeQuery } from "@/api/common";

export default {
  data() {
    return {
      username: "",
      password: "",
      captcha: "", // 用户输入的验证码
      captchaId: "",
      captchaSrc: "",
    };
  },
  created() {
    this.doCaptchaQuery();
  },
  methods: {
    // 获取验证码
    doCaptchaQuery() {
      CaptchaQuery().then((r) => {
        this.captchaId = r.data.captchaId;
        this.captchaSrc = r.data.picPath;
      });
    },

    // 登录数据验证
    doValidate() {
      if (!this.username) {
        this.$message.error("请输入账号");
        return false;
      }

      if (!this.password) {
        this.$message.error("请输入密码");
        return false;
      }

      if (!this.captcha) {
        this.$message.error("请输入验证码");
        return false;
      }

      return true;
    },

    // 登录
    doLogin() {
      const canSubmit = this.doValidate();
      if (!canSubmit) return;

      let oData = {
        username: this.username,
        password: this.password,
        captchaId: this.captchaId,
        captcha: this.captcha,
      };

      this.$store
        .dispatch("LoginHandler", oData)
        .then((r) => {
          console.log(r);
          this.$message({
            type: "success",
            message: "登录成功",
            duration: 1300,
          });
          setTimeout(() => {
            const path = window.localStorage.getItem("currentMenu");
            if (path) {
              this.$store.dispatch("setMenu", path);
              this.$router.push(path);
            } else {
              this.$store.dispatch("setMenu", "/dashboard");
              this.$router.push("/dashboard");
            }
          }, 500);
        })
        .catch((err) => {
          this.doCaptchaQuery();
        });
    },
  }, //methods-end
};
</script>

<style lang="less" scoped>
.wrap {
  width: 100vw;
  height: 100vh;
  min-width: 50%;
  background: url("~@/assets/img/bg_login.png");
  background-position: center;
  background-attachment: fixed;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-x: hidden;
  overflow-y: hidden;
}

.main-container {
  display: flex;
  justify-content: space-between;
  width: 1500px;
  height: 800px;
  padding: 30px 90px;
  margin: 0 auto;
  background-color: #fff;
  border-radius: 14px;
}

.block-left {
  width: 720px;

  .logo {
    display: block;
    margin-bottom: 12px;
    width: 135px;
    height: 52px;
  }

  .l-block-dec {
    display: block;
    width: 713px;
    height: 612px;
  }
}

.block-right {
  width: 420px;
}

.header {
  margin-bottom: 84px;
  padding-top: 58px;
  text-align: center;
  h2 {
    margin-bottom: 14px;
    font-size: 36px;
    color: #b4b4b4;
    font-weight: 600;
    line-height: 50px;
    background-image: linear-gradient(180deg, #9deeff 0%, #72a5ff 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  h3 {
    font-size: 20px;
    color: #d8d6d6;
    line-height: 24px;
    background: initial;
  }
}

.form {
  .input {
    box-sizing: border-box;
    width: 100%;
    padding-left: 42px;
    height: 56px;
    line-height: 56px;
    margin-bottom: 32px;
    color: #303133;
    background-color: #f6f7fb;
    font-size: 16px;
    border-radius: 30px;
    border: none;
    outline: none;
  }

  .btn-login {
    display: block;
    width: 100%;
    height: 56px;
    font-size: 20px;
    letter-spacing: 10px;
    margin: 20px auto 0;
    color: #fff;
    background-image: linear-gradient(180deg, #9deeff 0%, #72a5ff 100%);
    border: none;
    border-radius: 56px;
    // &:hover {
    //   background-image: linear-gradient(180deg, #72a5ff 0%, #72a5ff 100%);
    // }
  }
}

.captcha-wrap {
  position: relative;
}

.img-code-box {
  width: 162px;
  height: 54px;
  position: absolute;
  right: 0;
  top: 1px;
  background-color: rgba(255, 255, 255, 1);
  border-radius: 0 2px 2px 0;
  cursor: pointer;
}

input::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
  color: #b4b4b4;
}

input:-moz-placeholder,
textarea:-moz-placeholder {
  color: #b4b4b4;
}

input::-moz-placeholder,
textarea::-moz-placeholder {
  color: #b4b4b4;
}

input:-ms-input-placeholder,
textarea:-ms-input-placeholder {
  color: #b4b4b4;
}
</style>
